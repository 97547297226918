import { Button, Text, TextField } from "@radix-ui/themes";
import { getEndpoint } from "@transcribe-session/shared";
import { useState } from "react";

type Props = {
  onAuthenticated: (result: {
    deepgramKey: string;
    appToken: string;
  }) => void;
};

const Login: React.FC<Props> = ({ onAuthenticated }) => {
  const [appToken, setAppToken] = useState("");
  const [deepgramKey, setDeepgramKey] = useState("");

  const endpoint = getEndpoint();

  return (
    <>
      <p>Please provide the keys:</p>
      <form>
        <TextField.Input
          placeholder="App Token"
          mb="2"
          onChange={(event) => {
            setAppToken(event.target.value);
          }}
          value={appToken}
        />
        <TextField.Input
          placeholder="Deepgram Key"
          mb="2"
          onChange={(event) => {
            setDeepgramKey(event.target.value);
          }}
          value={deepgramKey}
        />
        <Button
          mb="6"
          disabled={appToken === ""}
          onClick={async (event) => {
            event.preventDefault();
            try {
              await fetch(
                `${endpoint}/parties/main/authenticate`,
                {
                  headers: {
                    Authorization: appToken,
                  },
                  method: "POST",
                }
              );

              onAuthenticated({ appToken, deepgramKey });
            } catch (error) {
              alert("Invalid App Token");
            }
          }}
        >
          Login
        </Button>
      </form>
      <Text size="1">API Endpoint: {endpoint}</Text>
    </>
  );
};

export default Login;
