import { Badge, Box, Button, Flex, Text } from "@radix-ui/themes";
import { getEndpoint, roomId } from "@transcribe-session/shared";
import cookie from "js-cookie";
import usePartySocket from "partysocket/react";
import { useEffect, useState } from "react";
import { createClient, LiveTranscriptionEvents } from "@deepgram/sdk";
import { testRecording } from "../fixtures/test-recording";

type Props = {
  appToken: string;
  deepgramKey: string;
};

const RecorderInterface: React.FC<Props> = ({ appToken, deepgramKey }) => {
  const endpoint = getEndpoint();
  const [isRecording, setIsRecording] = useState(false);
  const [language, setLanguage] = useState<
    "en" | "en-GB" | "en-US" | "en-NZ" | "de"
  >("en");
  const sessionId = "current-session-id";
  const [isRelayConnected, setIsRelayConnected] = useState(false);
  const [isTranscriberConnected, setIsTranscriberConnected] = useState(false);
  const [text, setText] = useState("");
  const [draftText, setDraftText] = useState("");

  const partykitSocket = usePartySocket({
    room: roomId,
    onOpen() {
      // console.log("Partykit Opened");
      setIsRelayConnected(true);
    },
    onClose() {
      // console.log("Partykit Closed");
      setIsRelayConnected(false);
    },
    onError() {
      console.log("Partykit Error");
    },
    query: async () => {
      return { token: appToken }; // attach the token to the query string
    },
  });

  const transformAndSendMessage = (received: any) => {
    if (partykitSocket) {
      const transcript = received.channel.alternatives[0].transcript;
      if (transcript && received.is_final) {
        partykitSocket.send(
          JSON.stringify({
            type: "final-text",
            text: transcript,
            sessionId,
          })
        );
        setText((currentText) => currentText + transcript + " ");
      } else if (transcript) {
        partykitSocket.send(
          JSON.stringify({
            type: "draft-text",
            text: transcript,
            sessionId,
          })
        );
        setDraftText(transcript);
      }
    }
  };

  useEffect(() => {
    if (!isRecording) return;

    let mediaRecorder: MediaRecorder;
    const deepgram = createClient(deepgramKey);
    const dgConnection = deepgram.listen.live({
      model: "nova",
      punctuate: true,
      interim_results: true,
      smart_formatting: true,
      language,
    });

    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      mediaRecorder = new MediaRecorder(stream);

      dgConnection.on(LiveTranscriptionEvents.Open, () => {
        setIsTranscriberConnected(true);

        dgConnection.on(LiveTranscriptionEvents.Transcript, (data) => {
          transformAndSendMessage(data);
        });

        dgConnection.on(LiveTranscriptionEvents.Close, () => {
          setIsTranscriberConnected(false);
        });

        mediaRecorder.addEventListener("dataavailable", (event) => {
          if (event.data.size > 0) {
            dgConnection.send(event.data);
          }
        });
        mediaRecorder.start(100);
      });
    });

    return () => {
      mediaRecorder?.stop();
      dgConnection.requestClose();
    };
  }, [isRecording]);

  return (
    <>
      <Box mt="4">
        <Text size="1">API Endpoint: {endpoint}</Text>
      </Box>
      <Flex gap="2" mt="4" align="center">
        <Text>Relay connection:</Text>
        {isRelayConnected ? (
          <Badge color="green" size="2" radius="full">
            Connected
          </Badge>
        ) : (
          <Badge color="red" size="2" radius="full">
            Disconnected
          </Badge>
        )}
      </Flex>
      <Flex gap="2" mt="4" align="center">
        <Text>Language:</Text>
        <select
          disabled={isRecording}
          value={language}
          onChange={(e) => {
            // @ts-expect-error fix it by parsing the value
            setLanguage(e.target.value);
          }}
        >
          <option value="en">English</option>
          <option value="en-GB">English (UK)</option>
          <option value="en-US">English (US)</option>
          <option value="en-NZ">English (NZ)</option>
          <option value="de">German</option>
        </select>
      </Flex>

      <Flex gap="2" mt="4" align="center">
        <Button
          disabled={isRecording}
          onClick={() => {
            setIsRecording(true);
          }}
        >
          Start Transcribing
        </Button>
        <Button
          disabled={!isRecording}
          onClick={() => {
            setIsRecording(false);
          }}
        >
          Stop Transcribing
        </Button>
      </Flex>

      <Flex gap="2" mt="4" align="center">
        <Text>Deepgram connection:</Text>
        {isTranscriberConnected ? (
          <Badge color="green" size="2" radius="full">
            Connected
          </Badge>
        ) : (
          <Badge color="red" size="2" radius="full">
            Disconnected
          </Badge>
        )}
      </Flex>

      <Flex mt="8" mb="2" gap="2">
        <Button
          onClick={async () => {
            // partykitSocket.send("Test data");
            for (const transcript of testRecording) {
              await new Promise((resolve) => setTimeout(resolve, 50));
              // console.log(transcript);
              transformAndSendMessage(transcript);
            }
          }}
        >
          Send Test data to server
        </Button>
        <Button
          onClick={async (event) => {
            try {
              const result = await fetch(`${endpoint}/parties/main/${roomId}`, {
                headers: { Authorization: appToken },
                body: JSON.stringify({ type: "reset-session", sessionId }),
                method: "POST",
              });
              const { status } = await result.json();
              if (status === "ok") {
                alert("Successfully reset the session");
              }
            } catch (error) {
              alert("Failed to reset the session");
            }
          }}
        >
          Reset Session (will delete the current content on the server and for
          all current subscribers)
        </Button>
        <Button
          onClick={(event) => {
            cookie.remove("appToken");
            cookie.remove("deepgramToken");
            window.location.reload();
          }}
        >
          Logout
        </Button>
      </Flex>
      <hr />
      <Box pt="2">
        <Text>Preview (content sent to the relay server):</Text>
        <p
          style={{
            padding: 40,
            fontSize: 24,
          }}
        >
          {text === "" && draftText === "" ? (
            <Text>Waiting for the transcript …</Text>
          ) : (
            <>
              {text} <span style={{ color: "gray" }}>{draftText}</span>
            </>
          )}
        </p>
      </Box>
    </>
  );
};

export default RecorderInterface;
